import React from 'react';
import { useEffect } from "react"
import { Script } from "gatsby"

const getCookie = (cookieKey: string) => {
  const cookieIndex = document.cookie.split(';').findIndex(element => element.includes(cookieKey));
  let cookieStr = ''

  if (cookieIndex >= 0) {
    cookieStr = document.cookie.split(';')[cookieIndex];
  }

  return cookieStr.trim().split('=')[1] || '';
}


export default function Analytics() {

  useEffect(() => {
    // if (pathname) {
    //   pageview(pathname);
    // }

    function loadIntercom () {
      var s=document.createElement('script');
      s.type='text/javascript';
      s.async=true;
      s.src=`https://widget.intercom.io/widget/${process.env.GATSBY_INTERCOM_APP_ID}`;
      var x=document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s,x);

      document.querySelectorAll('.showThisButton').forEach(b => {
        b?.classList.remove('__global-hide-element');
      })
    }

    // Function to load GTM dynamically
    const loadGTMAndIntercom = () => {
      const script = document.createElement('script');
      <>
          <Script
            id="gtm-script"
            dangerouslySetInnerHTML={{
              __html: 
            script.innerHTML = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', "${process.env.GATSBY_PUBLIC_GOOGLE_TAG_KEY}");

              var APP_ID = "${process.env.GATSBY_INTERCOM_APP_ID}";

              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){w._loadIntercom = l;}else if(w.attachEvent){w.attachEvent('onload',() => {w._loadIntercom = l});}else{w.addEventListener('load',() => {w._loadIntercom = l},false);}}})();
              

              // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ehyntyzy';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
          }}
        />
      </>
      document.body.appendChild(script);
      window._loadIntercom = loadIntercom;
    };

    // Load GTM immediately
    loadGTMAndIntercom();
    function gtag(){dataLayer.push(arguments);}

    // Check if consent cookie exists
    const analyticsConsentGiven = getCookie('analyticsConsent');
    const intercomConsentGiven = getCookie('intercomConsent');

    if (intercomConsentGiven === 'true') {
      if (typeof window !== 'undefined' && window?._loadIntercom) {
          window._loadIntercom();
      }
    }
 
    // If the consent cookie exists and is set to 'true', grant permissions
    if (analyticsConsentGiven === 'true') {
      // console.log(typeof window, window?._loadIntercom)
      // if (typeof window !== 'undefined' && window?._loadIntercom) {
      //   // setTimeout(() => {
      //     window._loadIntercom();
      //   // }, 1000)
      // }

      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted'
      });
    } else {
      // Set default settings to 'denied' if no consent cookie is found
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied'
      });
    }

    // Listen for the loadGTM event
    window.addEventListener('updateGTMConsent', () => {
      if (typeof window.dataLayer !== "undefined") {
        setTimeout(() => {
          window._loadIntercom();
        }, 1000)

        gtag('consent', 'update', {
          'ad_storage': 'granted',  // Example consent type
          'analytics_storage': 'granted',  // Another example consent type
          'functionality_storage': 'granted',
          'personalization_storage': 'granted',
          'security_storage': 'granted'
        });
        window.dataLayer.push({
          'event': 'cookie_consent_given',
        });
      }
    });

    window.addEventListener('updateIntercomConsent', () => {
      if (typeof window !== 'undefined' && window?._loadIntercom) {
        window._loadIntercom();
      }
    });
 
  }, []);

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    return null;
  }

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_PUBLIC_GOOGLE_TAG_KEY}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  );
}
